import '../sass/main.scss';
import 'flowbite';
import confetti from 'canvas-confetti';
import 'aos/dist/aos.css';
import AOS from 'aos';


AOS.init({
    duration: 1200, // Custom animation duration
  });
  

// Get the elements
const surpriseBtn = document.getElementById('surpriseBtn');
const randomWordDiv = document.getElementById('randomWord');
const nederlandsSpan = document.getElementById('word');
const sevenumsSpan = document.getElementById('NL1');
const categorySpan = document.getElementById('SE1');
// Declare the variables at the top, outside the function
let quizStartTime;


   // This function will reset all radio buttons on page load
window.onload = function() {
    document.body.style.visibility = 'visible';

    var radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach(function(radio) {
        radio.checked = false;  // Reset the radio buttons
        radio.disabled = false; // Re-enable the radio buttons
    });

    quizStartTime = new Date().getTime();  // Record the time when the quiz starts
    questionStartTime = quizStartTime;     // Set the question start time to quiz start time for the first question
}

document.addEventListener('DOMContentLoaded', function () {
    AOS.init({
        duration: 1200, // Custom animation duration
      });

            let home_page_quiz_questions = document.querySelectorAll('.home_page_quiz_question');

            home_page_quiz_questions.forEach(function(home_page_quiz_question) {
                home_page_quiz_question.addEventListener('click', function() {
                // Access the custom data-* attributes
                let choice = home_page_quiz_question.value;            
                let questionId = home_page_quiz_question.dataset.questionId;    
                let index = home_page_quiz_question.dataset.index; 
                let correctAnswer = home_page_quiz_question.dataset.correctAnswer;  

                // You can use these values to pass into a function
                    checkAnswer(this, choice, correctAnswer, index, questionId);
                });
            });

            let next_buttons = document.querySelectorAll('.next-button');
            next_buttons.forEach(function(next_button) {
                next_button.addEventListener('click', function() {
                    let currentIndex = next_button.dataset.index;
                    showNextQuestion(currentIndex);
                });
            });

            let prev_buttons = document.querySelectorAll('.prev-button');
            prev_buttons.forEach(function(prev_button) {
                prev_button.addEventListener('click', function() {
                    let currentIndex = prev_button.dataset.index;
                    showPreviousQuestion(currentIndex);
                });
            });

    // Check if the user ID exists in localStorage, if not, generate one
    let userId = localStorage.getItem('user_id');
    if (!userId) {
        userId = generateUUID();
        localStorage.setItem('user_id', userId); // Save it to localStorage
    }
    const button = document.getElementById('searchSubmitButton');
    if (!button) {
    } else {
    button.value = userId;
    }
    document.getElementById('searchSubmitButton').value = userId;
});

let currentProgress = 0; // Track the current question the user is on
let correctAnswers = 0;  // New variable to track correct answers on first try
let attemptData = {};     // Store attempt data for each question (e.g., {question_id: {attempts: 1, correct: 1}})

    // Function to check the answer
    function checkAnswer(element, selectedAnswer, correctAnswer, index, questionId) {
        index = parseInt(index);
        const timeSpent = (new Date().getTime() - questionStartTime) / 1000; // Time spent in seconds
        // Check if the question has already been answered
        if (!element.closest('.quiz-question-container').classList.contains('answered')) {
            element.closest('.quiz-question-container').classList.add('answered'); // Mark this question as answered
    
            if (selectedAnswer === correctAnswer) {
                correctAnswers++; // Increment correct answers on first try
            }
        }
    
        let isCorrect = 0; // Default to incorrect
        if (selectedAnswer === correctAnswer) {
            isCorrect = 1; // Mark as correct if the answer matches
            // correctAnswers++; // Increment correct answers on first try
        }
                
        // Store time spent and correctness in attemptData
        if (!attemptData[questionId]) {
            attemptData[questionId] = { attempts: 0, correct: 0, time_spent: 0 };
        }
        attemptData[questionId].time_spent = timeSpent;
        attemptData[questionId].correct = isCorrect;
    
        let userId = localStorage.getItem('user_id');
        // Send this data to the server
        sendPartialResults(userId, questionId, selectedAnswer, isCorrect, timeSpent);
        
    
        // Check if the question has already been answered
        let questionContainer = element.closest('.quiz-question-container');
        if (!questionContainer.classList.contains('answered')) {
            if (selectedAnswer === correctAnswer) {
                correctAnswers++; // Increment correct answers on first try
                attemptData[questionId].correct = 1; // Mark the question as answered correctly
                questionContainer.classList.add('answered');
            }
        }
        
        if (selectedAnswer === correctAnswer) {
            // Highlight the correct answer (you can add more styles if needed)
            element.parentElement.style.backgroundColor = "rgb(220 252 231)";  // Apply background color directly
            element.parentElement.classList.add("border-green-500");  // Add success styling
            element.parentElement.style.pointerEvents = "none";     // Disable hover effect by preventing interactions            
            element.parentElement.querySelector('label').style.color = "green"; // Change text color to green
    
            // Get the position of the correct answer button
            var rect = element.parentElement.getBoundingClientRect();
            var buttonX = rect.left + rect.width / 2; // Center of the button
            var buttonY = rect.top + rect.height / 2; // Center of the button
    
            // Adjust the confetti settings
            confetti({
                particleCount: 50, // Fewer particles for a smaller confetti effect
                startVelocity: 30, // Lower velocity for more control
                spread: 60,        // Spread of the confetti
                origin: {
                    x: buttonX / window.innerWidth,   // Normalize X position
                    y: buttonY / window.innerHeight   // Normalize Y position
                },
                scalar: 0.6 // Smaller confetti particles
            });
    
            // Show the "Next" button or trigger the flip animation after the last question
            if (index < 2) {  // Since there are only 3 questions, index < 2
                document.getElementById('next_' + index).style.display = "inline-block";
            } else {
                // Trigger the flip for the final question
                document.querySelector('.quiz-container').classList.add('flipped');
                 // Update the congrats message with the user's score
                 updateCongratsMessage();
                   // If it's the last question, show the congrats card
                if (index === 2) {  // Assuming 3 questions, index === 2 for the last one
                    showCongratsCard();
                }
            }
    
        } else {
            // Highlight the wrong answer
            element.parentElement.style.backgroundColor = "rgb(254 226 226)";  // Apply background color directly
            element.parentElement.classList.add("border-red-500");  // Add error styling
            element.parentElement.querySelector('label').style.color = "red"; // Change text color to red
    
            // Disable only the current wrong answer
            element.parentElement.querySelector('input').disabled = true;
        }
    
    }


// Add event listener to the button
surpriseBtn.addEventListener('click', function() {
    // Fetch random word via AJAX
    fetch('api/get_random_word.php')
        .then(response => response.json())
        .then(data => {
            // Update the page with the random word
            nederlandsSpan.textContent = data.word;
            sevenumsSpan.textContent = data.NL1;
            categorySpan.textContent = data.SE1;

            // Show the random word div
            randomWordDiv.style.display = 'block';
        })
        .catch(error => console.error('Error fetching random word:', error));
});


fetch('api/word_of_the_day.php')
.then(response => response.json())
.then(data => {
    document.getElementById('wofd_word').textContent = data.word;
    document.getElementById('wofd_NL1').textContent = data.NL1;
    document.getElementById('wofd_SE1').textContent = data.SE1;
})
.catch(error => console.error('Error fetching word of the day:', error));


// Function to show the "Congrats" card when the quiz is finished
function showCongratsCard() {
    let congratsCard = document.querySelector('.quiz-back');
    congratsCard.classList.add('show');
}

// Function to update the progress bar when navigating through the questions
function updateProgress(progress) {
    for (let i = 1; i <= 3; i++) {
        let progressBar = document.getElementById('progress_' + i);
        if (i <= progress) {
            progressBar.classList.remove('bg-gray-100');
            progressBar.classList.add('bg-blue-500');
        } else {
            progressBar.classList.remove('bg-blue-500');
            progressBar.classList.add('bg-gray-100');
        }
    }
}


// Function to calculate total time at the end of the quiz and format it as minutes:seconds
function updateCongratsMessage() {
    const totalTimeInSeconds = (new Date().getTime() - quizStartTime) / 1000; // Time in seconds

    // Calculate minutes and seconds
    const minutes = Math.floor(totalTimeInSeconds / 60);
    const seconds = Math.floor(totalTimeInSeconds % 60); // Remaining seconds

    // Format the time as minutes:seconds or just seconds
    const formattedTime = minutes > 0 ? `${minutes} minuut${minutes > 1 ? 'en' : ''} en ${seconds} seconden` : `${seconds} seconden`;

    let congratsMessage = document.getElementById('congrats-message');
    congratsMessage.textContent = `🎉 Gefeliciteerd! Je hebt ${correctAnswers} van de 3 vragen direct goed beantwoord. En het kostte je slechts ${formattedTime}!`;
    // Send the total time and final score to the server
}



let questionStartTime; // To track when the user starts a question

// Function to show the next question with a slide effect
function showNextQuestion(currentIndex) {
    currentIndex = parseInt(currentIndex);
    let currentQuestion = document.getElementById('question_' + currentIndex);
    let nextQuestion = document.getElementById('question_' + (currentIndex + 1));

      // Record start time for the new question
      questionStartTime = new Date().getTime();


    if (nextQuestion) {
        // Slide out the current question to the left
        currentQuestion.classList.add('slide-out');
        currentQuestion.classList.remove('slide-in', 'slide-in-reverse');

        setTimeout(function () {
            currentQuestion.style.display = 'none';
            nextQuestion.style.display = 'block';

            // Slide in the next question from the right
            nextQuestion.classList.remove('slide-out-reverse', 'slide-in-reverse');
            nextQuestion.classList.add('slide-in');

            // Update the progress bar for the next question
            currentProgress = currentIndex + 1; // Move to the next progress bar
            updateProgress(currentProgress + 1); // Add 1 to account for 1-based index
        }, 50); // Duration of slide-out animation
    }
}

// Function to show the previous question with a slide effect
function showPreviousQuestion(currentIndex) {
    currentIndex = parseInt(currentIndex);
    let currentQuestion = document.getElementById('question_' + currentIndex);
    let previousQuestion = document.getElementById('question_' + (currentIndex - 1));

    if (previousQuestion) {
        // Slide out the current question to the right (reverse)
        currentQuestion.classList.add('slide-out-reverse');
        currentQuestion.classList.remove('slide-in', 'slide-out');

        setTimeout(function () {
            currentQuestion.style.display = 'none';
            previousQuestion.style.display = 'block';

            // Slide in the previous question from the left
            previousQuestion.classList.remove('slide-in', 'slide-out-reverse');
            previousQuestion.classList.add('slide-in-reverse');

            // Update the progress bar for the previous question
            currentProgress = currentIndex - 1; // Move to the previous progress bar
            updateProgress(currentProgress + 1); // Add 1 to account for 1-based index
        }, 50); // Duration of slide-out animation
    }
}
// Function to send partial results to the server and handle confirmation
function sendPartialResults(userId, questionId, selectedAnswer, isCorrect, timeSpent) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'api/save_partial_results.php', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    
    const data = JSON.stringify({
        question_id: questionId,
        user_id: userId, // Include the unique user ID
        selected_option: selectedAnswer,
        is_correct: isCorrect,
        time_spent: timeSpent
    });
    
    // Add an event listener to confirm when the data is successfully sent
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            // The request is complete, and the server responded successfully
            // console.log('Data successfully sent to the server.');
            console.log('Response from server:', xhr.responseText); // Log the server's response
        } else if (xhr.readyState === 4) {
            // The request completed, but the server returned an error
            // console.log('Error sending data to the server.');
            // console.log('Server response:', xhr.responseText); // Log the error response
        }
    };

    // Print the data being sent for debugging
    // console.log('Sending the following data to the server:', data);
    
    // Send the data to the server
    xhr.send(data);
}


// Function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

